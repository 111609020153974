<template>
  <div class="tooltip-container" @click="active = true">
    <span :id="unique" v-if="active" class="text-tooltip-content">
      <slot name="tooltip"></slot>
      <div id="arrow" data-popper-arrow></div>
    </span>
    <span ref="content" class="content-container" v-click-outside="close">
      <slot name="content"></slot>
    </span>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import ClickOutside from "vue-click-outside";

export default {
  name: "TjingTextWrapTooltip",
  props: ["position", "unique"],
  data() {
    return {
      active: false,
      popperInstance: null,
    };
  },
  directives: {
    ClickOutside,
  },
  watch: {
    active: function (newValue, oldValue) {
      if (newValue) {
        this.open();
      } else {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
    },
  },
  computed: {},
  methods: {
    close() {
      this.active = false;
    },
    open() {
      this.$nextTick(() => {
        const button = this.$refs.content;
        const tooltip = document.getElementById(this.unique);

        this.popperInstance = createPopper(button, tooltip, {
          placement: "top",
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                mainAxis: true,
                padding: { right: 12, left: 12 },
              },
            },
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
          ],
        });
      });
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style >
</style>

<style lang="scss" scoped>
@import "@/styles/Typography";

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

.text-tooltip-content {
  z-index: 1;
  background: $midnight;
  color: white;
  padding: 18px;
  border-radius: 10px;
  animation-name: fade;
  animation-duration: 0.3;
  max-width: 300px;
  text-align: left;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
</style>
